export const handleTouchStart = (event, setTouchStartX) => {
    setTouchStartX(event.touches[0].clientX);
  };
  
  export const handleTouchMove = (event, setTouchEndX) => {
    setTouchEndX(event.touches[0].clientX);
  };
  
  export const handleTouchEnd = (touchStartX, touchEndX, showNext, showPrevious) => {
    if (touchStartX - touchEndX > 50) {
      showNext();
    } else if (touchEndX - touchStartX > 50) {
      showPrevious();
    }
  };
  