
import React from 'react';
import './politicadeprivacidade.css';

const PoliticadePrivacidade = () => {
  return (
    <div className="politica-container">
      <h1>Política de Privacidade</h1>
      <p>
        1. Esta Política de Privacidade se refere aos dados pessoais que a nohal.marketing poderá obter quando seus Usuários
        utilizam dos serviços prestados durante a navegação dentro de nosso ambiente virtual. Nesta Política de Privacidade, será
        descrita de que forma serão coletados e armazenados dados a respeito dos Usuários, assim como a forma pela qual tais
        dados poderão ser utilizados e armazenados nos termos da Lei 12.965/2014 (“Marco Civil da Internet”), do Decreto 8.771
        /2016 e da Lei n.o 13.709/2018 (Lei Geral de Proteção de Dados).
        Ao aceitar a presente Política de Privacidade é declarado que todo seu conteúdo foi devidamente lido e aceito, tendo assim
        todas as cláusulas devidamente validadas pelo Usuário para que atuem em pleno vigor.
        Ademais, deixa claro que esta Política de Privacidade poderá ser atualizada a qualquer tempo, a exclusivo critério dos
        administradores do ambiente virtual aqui discutido. Porém, deixa claro que toda e qualquer atualização será devidamente
        comunicada aos Usuários para nova aceitação de modo que, caso continuem em utilizar os serviços providenciados, estarão
        automaticamente concordando com as alterações realizadas no corpo desta política.
      </p>
      <h2>Obtenção, Armazenamento e Cuidado dos dados pessoais coletados</h2>
      <p>
        2. Aceitando nossa política de Privacidade, fica concedido, por livre e espontânea vontade, permissão para a coleta
        e armazenamento dos dados pessoais dos Usuários, que serão tratados da forma abaixo descrita:
        Poderão ser coletados informações fornecidas no cadastro ao longo do uso dos Serviços e durante a sua navegação
        em nossa plataforma.
        Eventuais dados de navegação serão utilizados para possibilitar o seu acesso e saber como entrar em contato com o
        Usuário quando for necessário. Além de utilizados para compreender seus interesses, como forma de garantir
        constante melhoria e evolução dos serviços providenciados.
        Os dados pessoais, poderão ser armazenados em outros países onde a nohal.marketing ou suas afiliadas possuam
        presença. Caso tais jurisdições possuam diferentes leis de proteção de dados, fica estabelecido o
        compromisso de armazenar e cuidar dos dados de acordo com tais leis e a presente Política de Privacidade.
        Os Dados pessoais poderão ser utilizados de forma anônima para fins estatísticos e de controle e melhoria dos
        nossos serviços.
        Serão estabelecidas medidas de segurança razoáveis para proteger todos os dados pessoais providenciados.
        Entretanto, lembramos que não existe uma medida de segurança 100% eficaz.
        Eventualmente, todos os dados coletados pelo Usuário durante o uso dos serviços prestados, poderão ser excluídos
        no momento em que o Usuário desejar, dito isso, somos obrigados a manter os registros de conexão durante o prazo
        legal.
        Esta Política de Privacidade se aplica a todos os websites detidos pela nohal.marketing ou qualquer outra página,
        mídia social, ferramenta, software ou conteúdo de sua propriedade.
      </p>
      <p>
        3. O fato de usar o site e / ou serviço disponibilizado confirma o consentimento inequívoco e incondicional do Usuário com
        esta Política, incluindo os termos de processamento de seus dados pessoais. Na ausência de consentimento do usuário com
        esta política e os termos de processamento de seus dados pessoais, o Usuário deve parar de usar o site e / ou o serviço
        providênciados, reservando à administração o direito de impedir o acesso do referido Usuário.
      </p>
      <p>
        4. Este ambiente é destinado a usuários com 18 (dezoito) anos de idade ou mais. Se você tem menos de 18 (dezoito) anos,
        não poderá usar ou registrar-se para usar este site ou seus serviços sem a permissão ou consentimento dos pais. Ao
        concordar com esta política de Privacidade, você tem a capacidade legal necessária para cumprir e ficar vinculado por esta
        política de Privacidade.
      </p>
      <h2>Armazenamento de Informações</h2>
      <p>
        5. É reconhecida a natureza sensível e confidencial dos dados e demais informações obtidas e armazenadas. Assim fica
        estabelecido o compromisso de manter estas informações de natureza confidencial em sigilo, sem utilizá-las ou divulgá-las
        sem a autorização do Usuário, exceto nos termos previstos nos Termos de Uso e na Política de Privacidade, bem como nos
        limites necessários para a execução das obrigações contratuais e legais, assim como para a defesa dos interesses da nohal.
        marketing e dos Usuários.
      </p>
      <h2>Cuidado com as informações e uso de Cookies</h2>
      <p>
        6. O Usuário concorda que a nohal.marketing poderá coletar, registrar, organizar, acumular, armazenar, atualizar, extrair,
        usar, transferir, incluindo transferência para outros países onde possua parceiros e/ou afiliadas, remover e destruir dados
        pessoais e outras informações.
        7. Os atos descritos acima poderão ser processados de forma manual e/ou com o uso de automação. O presente
        consentimento é válido até a sua retirada das configurações do Usuário e/ou até que seja solicitado pelo Usuário de forma
        direta. A solicitação pode ser enviada por escrito para o endereço eletrônico: contato@nohall.marketing
        8. Dentro dos limites da legislação aplicável, a nohal.marketing tem o direito de transferir as informações fornecidas pelo
        Usuário para terceiros.
        9. No tratamento de dados pessoais, serão tomadas as medidas legais, técnicas e organizacionais necessárias para proteger
        os dados pessoais contra o acesso não autorizado ou acidental, destruição, modificação, bloqueio, cópia, disposição,
        distribuição de dados pessoais, bem como outras ações ilegais em relação a dados pessoais em cumprimento dos requisitos
        da legislação brasileira e/ou qualquer outra que venha a ser aplicável. O Usuário concorda que algumas das informações,
        incluindo dados pessoais, ficam disponíveis publicamente ao usar determinados serviços e / ou o site.
        10. nohal.marketing faz uso de cookies. Ao acessar nosso site, você concorda em usar cookies nos termos da nossa Política
        de Cookies. Salienta-se que algum dos nossos parceiros de site podem usar cookies.
      </p>
      <h2>Manutenção dos dados pelo Usuário</h2>
      <p>
        11. O usuário tem o direito de requerer a exclusão de seus dados pessoais coletados durante o uso do ambiente eletrônico
        disponibilizado a qualquer momento, utilizando-se serviço relevante disponibilizado pelo próprio ambiente, ou por meio de
        contato direto com a administração por meio do endereço eletrônico disponibilizado acima. Estes direitos podem ser
        restringidos da maneira prescrita pela legislação brasileira.
        12. Caso o Usuário tenha ciência de que seus dados pessoais tenham sido comprometidos, de forma que poderá afetar seu
        acesso ao ambiente eletrônico providenciado, ele deverá comunicar imediatamente a administração para que sejam tomadas
        todas a medidas de segurança necessárias.
        13. O Usuário é o único responsável por suas ações relacionadas ao uso do site e / ou serviços disponibilizados,
        significando que, se tais ações resultarem em violação dos direitos e interesses legítimos de terceiros, bem como descumpre
        com a legislação do Brasil, o Usuário concorda em indenizar os prejuízos causados à nohal.marketing e / ou terceiros como
        resultado da não execução ou má execução das obrigações do Usuário sob esta Política e / ou legislação.
      </p>
      <h2>Responsabilidades e Competências</h2>
      <p>
        14. O Usuário usa os Serviços por sua conta e risco, sendo o único responsável por perdas incorridas como resultado do
        mal-uso pelo ambiente e / ou dos serviços da Empresa.
        15. A nohal.marketing coopera com as autoridades competentes e com terceiros para garantir o cumprimento das leis,
        salvaguardar a integridade e a segurança da Plataforma e de seus usuários, e impedir condutas que prejudiquem a
        integridade moral e a honra das pessoas físicas ou jurídicas envolvidas.
        16. As disposições desta Política são regidas pelas leis do Brasil. Se, por uma razão ou outra, uma ou mais disposições
        desta Política forem consideradas inválidas, isso não afeta as demais disposições.
        17. Para todas as questões o Usuário pode enviar um pedido para o endereço da empresa para a seguinte conta:
        contato@nohall.marketing
      </p>
    </div>
  );
};

export default PoliticadePrivacidade;
