import React, { useState } from 'react';
import { Icones } from './icones';
import setaesquerda from './icons/seta-esquerda.svg';
import setadireita from './icons/seta-direita.svg';
import './servicosoferecidos.css';
import { handleTouchStart, handleTouchMove, handleTouchEnd } from './touchHandler';


const ServicosOferecidos = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null); /* Atualização */
  const [touchEndX, setTouchEndX] = useState(null);  /* Atualização */

  const servicos = [
    {
      icone: 'branding',
      titulo: 'Branding',
      texto: 'Desenvolvemos identidades de marcas. Criamos conexões emocionais, consolidando a presença e reputação da sua marca.',
    },
    {
      icone:'Experiências',
      titulo: 'Experiências Digitais',
      texto: 'Criamos experiências online cativantes, desde websites intuitivos até aplicativos envolventes. O foco é proporcionar interações memoráveis que solidificam a relação entre sua marca e seus clientes.',
    },
    {
      icone:'Conteúdo',
      titulo: 'Conteúdo',
      texto: 'Produzimos conteúdo relevante e envolvente, adaptado para seu público-alvo. Desde artigos persuasivos até mídia visual, nosso objetivo é atrair, informar e envolver sua audiência.',
    },
    {
      icone:'Comércio',
      titulo: 'Comércio Eletrônico',
      texto: 'Desenvolvemos soluções de comércio eletrônico eficientes, proporcionando uma plataforma robusta para suas operações online. Facilitamos transações seguras e experiências de compra excepcionais.',
    },
    {
      icone:'Mídia',
      titulo: 'Mídia Paga',
      texto: 'Implementamos estratégias de marketing abrangentes, desde campanhas publicitárias no Google, Facebook e Instagram até a gestão de redes sociais. Maximizamos a visibilidade da sua marca, impulsionando o reconhecimento e a aquisição de clientes.',
    },
    {
      icone:'Estratégia',
      titulo: 'Estratégia',
      texto: 'Planejamos estratégias de negócios sólidas, alinhadas com os objetivos da sua empresa. Nossas abordagens são baseadas em dados, garantindo tomadas de decisão informadas e resultados mensuráveis.',
    },
    {
      icone:'Ecrm',
      titulo: 'E-CRM',
      texto: 'Implementamos estratégias de comunicação com o cliente e sistemas de E-CRM eficazes para fortalecer a comunicação e o relacionamento com seus clientes. Isso garante uma abordagem personalizada e uma gestão eficiente das interações.',
    },
    {
      icone: 'branding',
      titulo: 'Branding',
      texto: 'Desenvolvemos identidades de marcas. Criamos conexões emocionais, consolidando a presença e reputação da sua marca.',
    },
  ];

  const showNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % (servicos.length - 1));
  };

  const showPrevious = () => {
    setStartIndex((prevIndex) => (prevIndex - 1 + servicos.length - 1) % (servicos.length - 1));
  };

  return (
    <div className="servicosOferecidos" id="s1">
      <div id="tituloPrincipal">Quais são os <strong>serviços oferecidos?</strong></div>
      <div id="carrossel"  onTouchStart={(event) => handleTouchStart(event, setTouchStartX)}
        onTouchMove={(event) => handleTouchMove(event, setTouchEndX)}
        onTouchEnd={() => handleTouchEnd(touchStartX, touchEndX, showNext, showPrevious)}
        onMouseDown={(event) => setTouchStartX(event.clientX)}
        onMouseMove={(event) => {
          if (touchStartX) setTouchEndX(event.clientX)
        }}
        onMouseUp={() => {
          if (touchStartX) handleTouchEnd(touchStartX, touchEndX, showNext, showPrevious)
          setTouchStartX(null)
        }}
      >
        {servicos.slice(startIndex, startIndex + 2).map((servico, index) => (
          <div key={index} className={`retangulo ${index === 0 ? 'ativo' : ''}`}>
            <div className="titulo">
            <div className="titulo-texto">{Icones[servico.icone]}<span className='space'></span>{servico.titulo}</div>
            </div>
            <div className="texto">{servico.texto}</div>
            
          </div>
        ))}
      </div>
      <div id="setasContainer">
      <div id="setaEsquerda" onClick={showPrevious}><img src={setaesquerda}alt="Seta Esquerda" className="seta-esquerda-icon"/></div>
      <div id="setaDireita" onClick={showNext}><img src={setadireita}alt="Seta Direita" className="seta-direita-icon" /></div>
    </div>
    <p className='texto-cultivamos'> Cultivamos conexões reais de modo que as pessoas vivenciem belas experiências. Com um  parecer técnico e responsável, conduzimos a sua empresa às melhores tomadas de decisões  acerca dos investimentos em Marketing e Tecnologia. <strong>Unindo estratégia e excelência na  execução, sua marca se tornará MEMORÁVEL.</strong></p>

    </div>
  );
};

export default ServicosOferecidos;
