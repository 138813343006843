// src/componentes/layout/Layout.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './headerefooter/header.js';
import Rodape from './headerefooter/rodape';
import './layout.css'; // Importando o arquivo layout.css

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const bodyClass = location.pathname.replace('/', ''); // Obtém o nome da rota como classe
    document.body.className = bodyClass; // Define a classe no body
  }, [location.pathname]);

  return (
    <div className="Layout">
      <Header />
      <main>{children}</main>
      <Rodape />
    </div>
  );
}

export default Layout;
