// src/pages/Home.js
import React from 'react';
import Fachada from '../componentes/fachada/fachada';
import QuemSomos from '../componentes/quem-somos/quemsomos.js';
import Porque from '../componentes/porque/porque';
import ComoFunciona from '../componentes/comofunciona/Comofunciona';
import Servicosoferecidos from '../componentes/servicosoferecidos/servicosoferecidos.js';
import Contato from '../componentes/contato/contato.js';
import './animations.css';

const Home = () => (
  <div className="home">
    <Fachada />
    <QuemSomos />
    <Porque />
    <ComoFunciona />
    <Servicosoferecidos />
    <Contato />
  </div>
);

export default Home;
