// Rodape.js

import React from 'react';
import './rodape.css';
import envelopeIcon from './icones/envelope.svg';
import googleIcon from './icones/google.svg';
import linkedinIcon from './icones/linkedin.svg';
import instagramIcon from './icones/instagram.svg';

const Rodape = () => {
  return (
    <div>
      <div id="rodapeContainer">
        <div className="iconContainer">
        <a href="mailto:contato@nohall.marketing" target="_blank" rel="noopener noreferrer"><img src={envelopeIcon} alt="Envelope" /></a>
        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={googleIcon} alt="Google" /></a>
        <a href="https://www.linkedin.com/company/nohall" target="_blank" rel="noopener noreferrer"><img src={linkedinIcon} alt="LinkedIn" /></a>
        <a href="https://www.instagram.com/nohall.marketing" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="Instagram" /></a>
        </div>
        <p className="text1">© Feito por humanos para humanos.</p>
        <p className="text2">Nohall marketing e tecnologia</p>
      </div>
    </div>
  );
}

export default Rodape;
