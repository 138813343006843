// componentes/NotFound.js
import React from 'react';
import './NotFound.css'; // Importe o arquivo CSS

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>404 - Página não encontrada!</h1>
      <p>Ops! Você se aventurou em território desconhecido. <p>Explore outras áreas do nosso site enquanto nossos especialistas rastreiam o que você procura.</p> Clique abaixo para retornar à página inicial.</p>
      <a href="https://nohall.marketing/" className="btn-home">Voltar para a Home</a>
      <p className="team">Equipe Nohall, sua agência de Marketing e Tecnologia. 🚀</p>
    </div>
  );
}

export default NotFound;
