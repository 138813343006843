// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes'; // Importe o componente de rotas


import './App.css';

function App() {
  useEffect(() => {
    const gaTrackingId = 'G-PNEVRGV7D8';

    // Carrega o Google Analytics imediatamente
    const loadGA = () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', gaTrackingId);
    };

    loadGA(); // Carrega o Google Analytics imediatamente
  }, []);

  return (
    <Router>
      <div className="App">
        <AppRoutes /> 
      </div>
    </Router>
  );
}

export default App;
