import React, { useState } from 'react';
import './contato.css';
import whatsapp from './icone-whatsapp.png';
import Foto from './contato-otimizado.webp';
import Popup from './popup'; // Importe o componente Popup
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import '@fortawesome/fontawesome-svg-core/styles.css'; // Importe os estilos básicos do FontAwesome
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';
import cartinha from './cartinha.svg';

const Contato = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="contato" id="contato">
      <div className="coluna-esquerda">
        <h1 className='titulo'>Dê o primeiro <strong>passo rumo ao sucesso</strong></h1>
        <p className='texto'>Entre em contato com um dos nossos especialistas e agende a sua reunião de jornada de posicionamento.
          <p>Tornando o processo transparente e acessível a você. Utilizamos o Meet para proporcionar uma ótima interação.</p>
        </p>
        <div className="botoes">
          <button><img src={whatsapp} alt="WhatsApp" /><a href="https://wa.me/553531131109">+55 35 3113-1109</a></button>
          <button><img src={whatsapp} alt="WhatsApp" /><a href="https://wa.me/553131576018">+55 31 3157-6018</a></button>
        </div>
        <p className='textodebaixo'>Ou, se preferir, envie-nos um e-mail <p className='email'><a href="mailto:contato@nohall.marketing"><span><img src={cartinha} alt="cartinha de e-mail" className="cartinha" width="25" Height="25"/></span> Contato@nohall.marketing</a></p></p>

        {/* <button className="transparente">Contato@nohall.marketing</button> */}
      </div>
      <div className="coluna-direita">
        <img src={Foto} alt="Contato" loading="lazy" />
      </div>

      {/* Ícone do WhatsApp com ação de abrir o popup */}
      <a href="#popup" onClick={openPopup} aria-label="WhatsApp" className="whatsapp-icon-bottom" alt="WhatsApp">
        <FontAwesomeIcon icon={faSquareWhatsapp} className="whatsapp-icon" />
      </a>

      {/* Renderiza o Popup se isPopupOpen for verdadeiro */}
      {isPopupOpen && <Popup onClose={closePopup} />}
    </div>
  );
};

export default Contato;
