import React, { useState } from 'react';
import './cabecalho.css';
import logo from './Logo-nohall.svg';
import HamburgerMenu from './HamburgerMenu';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`header ${menuOpen ? 'menu-open' : ''}`}>
      <a href="/" id="logo" onClick={() => window.location.reload()}>
        <div className="logo" id="logo1">
          <img src={logo} alt="Logo" />
        </div>
      </a>
      <div className="menu">
        <ul className="menu-list">
          <li><a href="#quemsomos">Quem somos</a></li>
          <li><a href="#porque">Por quê?</a></li>
          <li><a href="#metodologia">Metodologia</a></li>
          <li><a href="#s1">Serviços</a></li>
          <li><a href="#contato">Contato</a></li>
        </ul>
      </div>
      {/* Passando o estado e a função para HamburgerMenu */}
      <HamburgerMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
    </div>
  );
};

export default Header;
