import React from 'react';
import './quemsomos.css';
import QuemSomosFoto from './quem-somos-webp.webp';

const QuemSomos = () => {
  return (
    <div className="quem-somos-container">
      <div className="quem-somos-left">
        <div id='quemsomos'>
          <h1 className="quem-somos-title">Quem<span className="somos-highlight"> somos?</span></h1>
          <div className="quem-somos-text">
            <p>
              Com mais de 10 anos de experiência juntos, a Nohall se fundiu pelo
              propósito de dois mineiros apaixonados por comunicação e tecnologia da
              informação.
            </p>
            <p>
              Aliados aos especialistas em Marca, Conteúdo, Experiências
              Digitais, Comércio Eletrônico, Marketing, Estratégia e E-CRM, nos
              transformamos em uma ponte que integra a expertise necessária para o
              sucesso do negócio e a fidelização do cliente.
            </p>
            <p>
              Com um parecer técnico e responsável, conduzimos a sua empresa às melhores
              tomadas de decisões acerca dos investimentos em comunicação e tecnologia da
              informação.
            </p>
          </div>
          <p className="mas-text">Mas por que investir em marketing e tecnologia?</p>
        </div>
      </div>
      <div className="quem-somos-right">
        <div className="quem-somos-image-container">
          <img
            src={QuemSomosFoto}
            alt="Quem Somos Imagem"
            className="quem-somos-image"
          />
        </div>
      </div>
    </div>
  );
};

export default QuemSomos;
