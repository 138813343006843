// icones.js

import React from 'react';
import ElipseSVG from './icons/elipse.svg';
import PenSVG from './icons/pen.svg';
import Computer from './icons/computer.svg';
import Revista from './icons/revista.svg';
import Sacolinha from './icons/sacolinha.svg';
import Moeda from './icons/moeda.svg';
import Lampada from './icons/lampada.svg';
import Pessoas from './icons/pessoas.svg';
import './icones.css';

export const Icones = {
  branding: (
    <div className="elipse-background" style={{ backgroundImage: `url(${ElipseSVG})` }}>
      <img src={PenSVG} alt="Pen Icon" className="pen-icon" />
    </div>
  ),
    Experiências: (
    <div className="elipse-background" style={{ backgroundImage: `url(${ElipseSVG})` }}>
      <img src={Computer} alt="Computer Icon" className="outros-icon" />
    </div>
    ),
    Conteúdo: (
        <div className="elipse-background" style={{ backgroundImage: `url(${ElipseSVG})` }}>
          <img src={Revista} alt="Revista Icon" className="outros-icon" />
        </div>
  ),
     Comércio: (
     <div className="elipse-background" style={{ backgroundImage: `url(${ElipseSVG})` }}>
       <img src={Sacolinha} alt="Sacolinha Icon" className="outros-icon" />
     </div>
),

     Mídia: (
     <div className="elipse-background" style={{ backgroundImage: `url(${ElipseSVG})` }}>
        <img src={Moeda} alt="Moeda Icon" className="outros-icon" />
     </div>
),

     Estratégia: (
     <div className="elipse-background" style={{ backgroundImage: `url(${ElipseSVG})` }}>
       <img src={Lampada} alt="Lampada Icon" className="outros-icon" />
     </div>
),

     Ecrm: (
     <div className="elipse-background" style={{ backgroundImage: `url(${ElipseSVG})` }}>
      <img src={Pessoas} alt="Pessoas Icon" className="outros-icon" />
     </div>
),
  // Adicione outros ícones conforme necessário
};
