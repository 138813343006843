import React from 'react';
import './porque.css'; // Importa o arquivo CSS
import marketing from './marketing.png'
import tecnologia from './tecnologia.png'
import linha from './Linha.svg'

const Porque = () => {
  return (
    <div className="porque-container" id="porque">
      <div className="espaco"></div>
      <div className="tituloabc">
        <h1><span className="porqueinvestirem">Por que investir em</span><br /><span className='subtituloinvestir'>marketing e tecnologia?</span></h1>
      </div>
      <div className="textoabc">
        <p>Enquanto o marketing constrói a ponte entre sua empresa e seus clientes, a tecnologia impulsiona a eficiência interna.<p>Juntas, essas duas forças criam um ambiente propício para o crescimento sustentável, aprimorando não apenas a presença da sua marca, mas também a eficácia operacional da sua empresa.</p></p>
      </div>
      <div className="conteiner-lado-a-lado">
        <div className="lado-esquerdo">
        <span><img src={marketing} alt="Marketing Icon" className="icon" width="51" height="51" /></span><span><img src={linha} alt="linha-svg" className="linha-svg" width="59" /></span><span className="titulo-especial">Marketing</span>
          <p className='textocorrido'>Investir em marketing é como contar a<br />história da sua empresa para o mundo.</p><p className='textocorrido'>É a maneira de se conectar com seu<br />público-alvo, criar conscientização<br />sobre seus produtos ou serviços e<br />construir uma marca forte.</p><p className='textocorrido'>Quando você investe em estratégias<br />de marketing eficazes, está<br />construindo relacionamentos<br />duradouros com seus clientes,<br />gerando confiança e lealdade.</p>
        </div>
        <div className="lado-direito">
        <span><img src={tecnologia} alt="Tecnologia Icon" className="icon" width="52" height="52" /></span><span><img src={linha} alt="linha-svg" className="linha-svg" width="59" /></span><span className="titulo-especial">Tecnologia</span>
          <p className='textocorrido' >A tecnologia é o motor que impulsiona<br />a inovação e a eficiência nas empresas.</p><p className='textocorrido'>Ao investir em tecnologia, você<br />automatiza processos, melhora a<br />produtividade e permanece<br />competitivo no mercado.</p><p className='textocorrido'>Além disso, estar atualizado com as<br />últimas tendências tecnológicas<br />garante que sua empresa esteja<br />preparada para enfrentar <br /> os desafios do futuro.</p>
        </div>
      </div>
      <div className="frase-final">
        <p>Isso não apenas atrai novos clientes, mas também mantém os existentes,<div className="frase-final-proporcionando">proporcionando um fluxo constante de receita.</div></p>
      </div>
    </div>
  );
};

export default Porque;
