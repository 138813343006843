import React from 'react';
import './fachada.css';
import Seta from './seta.svg'
import fundoOtimizado from './fundo-otimizado.webp';

const Fachada = () => {
  return (
    <div className="fachada">
      <link rel="preload" href={fundoOtimizado} as="image" className="imagem-da-fachada" />
      <div className="fachada-content">
        <div className="text-left">
          <p className="main-text">
            Conectando Futuros e Pessoas: <br />
            Estratégias, Marcas e Histórias.
          </p>
          <p className="sub-text">
            Somos a Nohall, uma empresa especializada <br />
            em soluções de marketing e tecnologia.
          </p>
          <button className="cta-button"><span><a href="#contato">Saiba mais</a></span><img src={Seta} alt="Ícone Seta" className="icone-seta" />
          </button>
        </div>
      </div>
    </div>
  );


};

export default Fachada;
