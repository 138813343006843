import React from 'react';
import './popup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Importe os estilos básicos do FontAwesome
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Popup = ({ onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <p>Olá! Com quem você deseja conversar?</p>
        <div className="buttons-container">
          <a href="https://wa.me/553131576018" target="_blank" rel="noopener noreferrer" >
          <button className='botaoopcao'><FontAwesomeIcon icon={faWhatsapp} className="whatsapp-opcao" /> Gustavo</button>
          </a>
          <a href="https://wa.me/553531131109" target="_blank" rel="noopener noreferrer" >
          <button className='botaoopcao'><FontAwesomeIcon icon={faWhatsapp} className="whatsapp-opcao" /> Gláucia</button>
          </a>
        </div>
        <button className="close-button" onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
};

export default Popup;
