// Comofunciona.js
import React from 'react';
import './Comofunciona.css'; // Certifique-se de que o arquivo de estilo está vinculado corretamente
import Fotoservico from './fotoservicos-otimizada.webp';

const Comofunciona = () => {
  return (
    <div className="comofunciona-container">
      <div className="imagem-container">
        {/* Imagem */}
        <img
          src={Fotoservico} alt="Imagem" loading="lazy" />
      </div>
      <div className="conteudo-container" id="metodologia">
        {/* Título */}
        <h1 className="titulo-comofunciona">Como funciona a nossa <strong>prestação de serviços?</strong></h1>
        {/* Texto */}
        <p className="descricao-comofunciona">
          Nosso processo inicia-se com uma reunião estratégica, onde delineamos uma jornada de posicionamento digital personalizada para sua empresa.</p><p className="descricao-comofunciona" >Durante esse encontro, desenvolvemos uma visão abrangente, alinhada com os objetivos imediatos e futuros, baseando-nos nos resultados desejados e nas nuances do mercado. Essa jornada serve como o mapa que guiará nossas ações.</p><p className="descricao-comofunciona" >Avaliamos minuciosamente seus investimentos em marketing e tecnologia, identificando áreas de oportunidade e pontos de melhoria. Essa análise profunda nos permite tomar decisões informadas para conduzir sua empresa em direção ao sucesso.
        </p>
      </div>
    </div>
  );
};

export default Comofunciona;
