// Routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './componentes/Layout';
import Home from './componentes/Home';
import PoliticaDePrivacidade from './componentes/lgpd/politicadeprivacidade/politicadeprivacidade';
import TermosDeUso from './componentes/lgpd/termosdeuso/termosdeuso';
import NotFound from './componentes/404/NotFound'; 


const AppRoutes = () => (
  <Layout>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/politica-de-privacidade" element={<PoliticaDePrivacidade />} classname="politica-container" />
      <Route path="/termos-de-uso" element={<TermosDeUso />} classname="termos-container" />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Layout>
);

export default AppRoutes;
